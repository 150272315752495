import { FileModel, AddressModel, CurrencyModel } from '.';
import { unescape } from 'html-escaper';

/*
  status :
    0 = ปิดใช้งาน
    1 = เปิดใช้งาน
*/

export class SupplierModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.name = data.name? unescape(data.name): null;
    this.taxId = data.taxId? unescape(data.taxId): null;

    this.currency = new CurrencyModel(data.currency? data.currency: {});
    this.description = data.description? unescape(data.description): null;
    
    this.image = new FileModel(data.image? data.image: {});

    this.email = data.email? data.email: null;
    this.address = new AddressModel(data.address? data.address: {});
    
    this.telephones = data.telephones && data.telephones.length? data.telephones: [];
    this.telephonesStr = this.telephones.join(', ');
    
    this.line = data.line? data.line: null;
    this.facebook = data.facebook? data.facebook: null;
    this.instagram = data.instagram? data.instagram: null;
    this.website = data.website? data.website: null;

    this.status = data.status? data.status: 0;

    // Calculation
    this.distance = data.distance || data.distance===0 || data.distance!==999999
      ? Number(data.distance): null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
}
