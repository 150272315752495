import { ProductModel, ProductUnitModel, StockIncomingModel } from '.';

/*
  status
    0 = Inactive
    1 = Active
*/

export class StockModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.product = new ProductModel(data.product? data.product: {});
    this.leftQuantity = Number(data?.leftQuantity ?? 0);

    this.stocks = data.stocks && data.stocks.length 
      ? data.stocks.map(d => {
        return {
          productUnit: new ProductUnitModel(d.productUnit? d.productUnit: {}),
          stockIncoming: new StockIncomingModel(d.stockIncoming? d.stockIncoming: {}),
          quantity: Number(d?.quantity ?? 0),
          unitQuantity: Number(d?.unitQuantity ?? 0),
          leftQuantity: Number(d?.leftQuantity ?? 0),
          expiredAt: d.expiredAt? new Date(d.expiredAt): null,
        };
      }): [];

    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
}
