import { unescape } from 'html-escaper';

/*
  level : Number
    99 = Super Admin
    98 = Admin
    
    20 = Account Manager
    10 = Head of Sales
    
    2  = Sales
    1  = Employee
*/

export class UserRoleModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    this.name = data.name? unescape(data.name): null;
    this.level = data.level? data.level: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayName() {
    if(this.isValid()) return this.name;
    else return '';
  }
}
