import {
  STOCK_INCOMINGS, STOCKS,
} from '../actions/types';

const initialState = {
  stockIncomings: [],
  stocks: [],
};

const stockReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case STOCK_INCOMINGS:
      return { ...state, stockIncomings: action.payload };
    case STOCKS:
      return { ...state, stocks: action.payload };

    default:
      return state;
  }
};

export default stockReducer;