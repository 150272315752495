import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AlertPopup from './components/AlertPopup';
import Header from './components/Header';

import CryptoJS from 'crypto-js';
import { getUserModel } from './helpers/header';
import { API_URL, APP_PREFIX, TOKEN_KEY, REFRESH_KEY } from './actions/variables';
import { UserModel } from './models';

import {
  AuthSignInPage, AuthForgetPasswordPage, AuthResetPasswordPage,
  AuthCustomerSignupQrCodePage, AuthPageLoading,
} from './views/auth';
const AuthComingSoonPage = lazy(() => import('./views/auth/ComingSoonPage'));
const AuthPage404 = lazy(() => import('./views/auth/Page404'));


function App() {
  VerifySignedIn();
  return (
    <BrowserRouter>
      <div className="page-main">
        <Header />
        <Suspense fallback={<AuthPageLoading />}>
          <Routes>

            <Route path="/" element={<NotSignedInRoute element={AuthSignInPage} />} />
            <Route path="/auth/signin" 
              element={<NotSignedInRoute element={AuthSignInPage} />} />
            <Route path="/auth/forget-password" 
              element={<NotSignedInRoute element={AuthForgetPasswordPage} />} />
            <Route path="/auth/reset-password/*" 
              element={<NotSignedInRoute element={AuthResetPasswordPage} />} />

            <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" 
              element={<AuthCustomerSignupQrCodePage />} />
            

            {/* START: Admin *************************************************************** */}
            {/* Admin */}
            <Route path="/admin" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/DashboardPage'))} />} />
            <Route path="/admin/dashboard" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/DashboardPage'))} />} />
              
            <Route path="/admin/admins" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/AdminsPage'))} />} />
            <Route path="/admin/admin/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/AdminViewPage'))} />} />
            <Route path="/admin/admin/:process/*" 
              element={<ProtectedRoute auth={GuardSuperAdmin()} 
              element={lazy(() => import('./views/admin/AdminPage'))} />} />
            
            <Route path="/admin/account-managers" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/AccountManagersPage'))} />} />
            <Route path="/admin/account-manager/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/AccountManagerViewPage'))} />} />
            <Route path="/admin/account-manager/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/AccountManagerPage'))} />} />

            <Route path="/admin/head-of-sales" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/HeadOfSalesPage'))} />} />
            <Route path="/admin/head-of-sale/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/HeadOfSaleViewPage'))} />} />
            <Route path="/admin/head-of-sale/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/HeadOfSalePage'))} />} />

            <Route path="/admin/sales" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SalesPage'))} />} />
            <Route path="/admin/sale/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SaleViewPage'))} />} />
            <Route path="/admin/sale/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SalePage'))} />} />

            <Route path="/admin/employees" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/EmployeesPage'))} />} />
            <Route path="/admin/employee/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/EmployeeViewPage'))} />} />
            <Route path="/admin/employee/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/EmployeePage'))} />} />

            <Route path="/admin/settings/*" 
              element={<ProtectedRoute auth={GuardSuperAdmin()} 
              element={lazy(() => import('./views/admin/SettingsPage'))} />} />

            {/* Personal */}
            <Route path="/admin/profile" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProfileViewPage'))} />} />
            <Route path="/admin/profile/update" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProfilePage'))} />} />

            {/* Product */}
            <Route path="/admin/product-categories" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductCategoriesPage'))} />} />
            <Route path="/admin/product-category/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductCategoryViewPage'))} />} />
            <Route path="/admin/product-category/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductCategoryPage'))} />} />

            <Route path="/admin/products" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductsPage'))} />} />
            <Route path="/admin/product/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductViewPage'))} />} />
            <Route path="/admin/product/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/ProductPage'))} />} /> 

            {/* Supplier */}
            <Route path="/admin/suppliers" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SuppliersPage'))} />} />
            <Route path="/admin/supplier/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierViewPage'))} />} />
            <Route path="/admin/supplier/view/:dataId/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierViewPage'))} />} />
            <Route path="/admin/supplier/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierPage'))} />} />

            <Route path="/admin/supplier-prs" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierPrsPage'))} />} />
            <Route path="/admin/supplier-pos" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierPosPage'))} />} />
            <Route path="/admin/supplier-invoices" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierInvoicesPage'))} />} />
            <Route path="/admin/supplier-receipts" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/SupplierReceiptsPage'))} />} />

            {/* Stock */}
            <Route path="/admin/stock-reports" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/StockReportsPage'))} />} />
            <Route path="/admin/stock-incomings" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/StockIncomingsPage'))} />} />
            <Route path="/admin/stocks" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/StocksPage'))} />} />

            {/* Customer */}
            <Route path="/admin/customer-reports" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerReportsPage'))} />} />
            <Route path="/admin/customers" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomersPage'))} />} />
            <Route path="/admin/customer/view/:dataId" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerViewPage'))} />} />
            <Route path="/admin/customer/view/:dataId/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerViewPage'))} />} />
            <Route path="/admin/customer/:process/*" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerPage'))} />} />

            <Route path="/admin/customer-prs" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerPrsPage'))} />} />
            <Route path="/admin/customer-pos" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerPosPage'))} />} />
            <Route path="/admin/customer-invoices" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerInvoicesPage'))} />} />
            <Route path="/admin/customer-receipts" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerReceiptsPage'))} />} />

            <Route path="/admin/customer-orders" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/CustomerOrdersPage'))} />} />

            {/* Finance */}
            <Route path="/admin/finances" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/FinancesPage'))} />} />
            <Route path="/admin/employee-timesheets" 
              element={<ProtectedRoute auth={GuardAdmin()} 
              element={lazy(() => import('./views/admin/EmployeeTimesheetsPage'))} />} />
            {/* END: Admin ***************************************************************** */}

            {/* START: Account Manager *************************************************************** */}
            {/* Account Manager */}
            <Route path="/account-manager" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/DashboardPage'))} />} />
            <Route path="/account-manager/dashboard" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/DashboardPage'))} />} />

            <Route path="/account-manager/head-of-sales" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/HeadOfSalesPage'))} />} />
            <Route path="/account-manager/head-of-sale/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/HeadOfSaleViewPage'))} />} />
            <Route path="/account-manager/head-of-sale/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/HeadOfSalePage'))} />} />

            <Route path="/account-manager/sales" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SalesPage'))} />} />
            <Route path="/account-manager/sale/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SaleViewPage'))} />} />
            <Route path="/account-manager/sale/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SalePage'))} />} />

            <Route path="/account-manager/employees" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/EmployeesPage'))} />} />
            <Route path="/account-manager/employee/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/EmployeeViewPage'))} />} />
            <Route path="/account-manager/employee/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/EmployeePage'))} />} />

            {/* Personal */}
            <Route path="/account-manager/profile" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProfileViewPage'))} />} />
            <Route path="/account-manager/profile/update" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProfilePage'))} />} />

            {/* Product */}
            <Route path="/account-manager/product-categories" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductCategoriesPage'))} />} />
            <Route path="/account-manager/product-category/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductCategoryViewPage'))} />} />
            <Route path="/account-manager/product-category/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductCategoryPage'))} />} />

            <Route path="/account-manager/products" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductsPage'))} />} />
            <Route path="/account-manager/product/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductViewPage'))} />} />
            <Route path="/account-manager/product/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/ProductPage'))} />} /> 

            {/* Supplier */}
            <Route path="/account-manager/suppliers" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SuppliersPage'))} />} />
            <Route path="/account-manager/supplier/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierViewPage'))} />} />
            <Route path="/account-manager/supplier/view/:dataId/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierViewPage'))} />} />

            <Route path="/account-manager/supplier-prs" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierPrsPage'))} />} />  
            <Route path="/account-manager/supplier-pos" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierPosPage'))} />} />
            <Route path="/account-manager/supplier-invoices" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierInvoicesPage'))} />} />
            <Route path="/account-manager/supplier-receipts" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/SupplierReceiptsPage'))} />} />            

            {/* Stock */}
            <Route path="/account-manager/stock-reports" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/StockReportsPage'))} />} />
            <Route path="/account-manager/stock-incomings" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/StockIncomingsPage'))} />} />
            <Route path="/account-manager/stocks" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/StocksPage'))} />} />

            {/* Customer */}
            <Route path="/account-manager/customer-reports" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerReportsPage'))} />} />
            <Route path="/account-manager/customers" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomersPage'))} />} />
            <Route path="/account-manager/customer/view/:dataId" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerViewPage'))} />} />
            <Route path="/account-manager/customer/view/:dataId/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerViewPage'))} />} />
            <Route path="/account-manager/customer/:process/*" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerPage'))} />} />

            <Route path="/account-manager/customer-prs" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerPrsPage'))} />} />
            <Route path="/account-manager/customer-pos" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerPosPage'))} />} />
            <Route path="/account-manager/customer-invoices" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerInvoicesPage'))} />} />
            <Route path="/account-manager/customer-receipts" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerReceiptsPage'))} />} />

            <Route path="/account-manager/customer-orders" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/CustomerOrdersPage'))} />} />


            {/* Finance */}
            <Route path="/account-manager/finances" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/FinancesPage'))} />} />
            <Route path="/account-manager/employee-timesheets" 
              element={<ProtectedRoute auth={GuardAccountManager()} 
              element={lazy(() => import('./views/account-manager/EmployeeTimesheetsPage'))} />} />
            {/* END: Account Manager ***************************************************************** */}

            {/* START: Head of Sales *************************************************************** */}
            {/* Head of Sales */}
            <Route path="/head-of-sales" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/DashboardPage'))} />} />
            <Route path="/head-of-sales/dashboard" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/DashboardPage'))} />} />

            <Route path="/head-of-sales/sales" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/SalesPage'))} />} />
            <Route path="/head-of-sales/sale/view/:dataId" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/SaleViewPage'))} />} />
            <Route path="/head-of-sales/sale/:process/*" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/SalePage'))} />} />

            {/* Personal */}
            <Route path="/head-of-sales/profile" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProfileViewPage'))} />} />
            <Route path="/head-of-sales/profile/update" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProfilePage'))} />} />

            {/* Product */}
            <Route path="/head-of-sales/product-categories" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProductCategoriesPage'))} />} />
            <Route path="/head-of-sales/product-category/view/:dataId" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProductCategoryViewPage'))} />} />

            <Route path="/head-of-sales/products" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProductsPage'))} />} />
            <Route path="/head-of-sales/product/view/:dataId" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/ProductViewPage'))} />} />

            {/* Supplier */}
            <Route path="/head-of-sales/suppliers" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/SuppliersPage'))} />} />
            <Route path="/head-of-sales/supplier/view/:dataId" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/SupplierViewPage'))} />} />

            {/* Stock */}
            <Route path="/head-of-sales/stocks" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/StocksPage'))} />} />

            {/* Customer */}
            <Route path="/head-of-sales/customer-reports" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerReportsPage'))} />} />
            <Route path="/head-of-sales/customers" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomersPage'))} />} />
            <Route path="/head-of-sales/customer/view/:dataId" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerViewPage'))} />} />
            <Route path="/head-of-sales/customer/view/:dataId/*" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerViewPage'))} />} />
            <Route path="/head-of-sales/customer/:process/*" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerPage'))} />} />

            <Route path="/head-of-sales/customer-prs" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerPrsPage'))} />} />
            <Route path="/head-of-sales/customer-pos" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerPosPage'))} />} />
            <Route path="/head-of-sales/customer-invoices" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerInvoicesPage'))} />} />
            <Route path="/head-of-sales/customer-receipts" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerReceiptsPage'))} />} />
            <Route path="/head-of-sales/customer-orders" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/CustomerOrdersPage'))} />} />

            {/* Finance */}
            <Route path="/head-of-sales/finances" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/FinancesPage'))} />} />
            <Route path="/head-of-sales/employee-timesheets" 
              element={<ProtectedRoute auth={GuardHeadOfSales()} 
              element={lazy(() => import('./views/head-of-sales/EmployeeTimesheetsPage'))} />} />
            {/* END: Head of Sales ***************************************************************** */}

            {/* START: Sales *************************************************************** */}
            {/* Sales */}
            <Route path="/sales" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/DashboardPage'))} />} />
            <Route path="/sales/dashboard" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/DashboardPage'))} />} />

            {/* Personal */}
            <Route path="/sales/profile" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProfileViewPage'))} />} />
            <Route path="/sales/profile/update" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProfilePage'))} />} />

            {/* Product */}
            <Route path="/sales/product-categories" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProductCategoriesPage'))} />} />
            <Route path="/sales/product-category/view/:dataId" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProductCategoryViewPage'))} />} />

            <Route path="/sales/products" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProductsPage'))} />} />
            <Route path="/sales/product/view/:dataId" 
              element={<ProtectedRoute auth={GuardSales()} 
              element={lazy(() => import('./views/sales/ProductViewPage'))} />} />

            {/* Supplier */}
            <Route path="/sales/suppliers"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/SuppliersPage'))} />} />
            <Route path="/sales/supplier/view/:dataId"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/SupplierViewPage'))} />} />

            {/* Stock */}
            <Route path="/sales/stocks"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/StocksPage'))} />} />

            {/* Customer */}
            <Route path="/sales/customer-reports"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerReportsPage'))} />} />
            <Route path="/sales/customers"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomersPage'))} />} />
            <Route path="/sales/customer/view/:dataId"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerViewPage'))} />} />
            <Route path="/sales/customer/view/:dataId/*"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerViewPage'))} />} />
            <Route path="/sales/customer/:process/*"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerPage'))} />} />

            <Route path="/sales/customer-prs"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerPrsPage'))} />} />
            <Route path="/sales/customer-pos"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerPosPage'))} />} />
            <Route path="/sales/customer-invoices"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerInvoicesPage'))} />} />
            <Route path="/sales/customer-receipts"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerReceiptsPage'))} />} />

            <Route path="/sales/customer-orders"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/CustomerOrdersPage'))} />} />

            {/* Finance */}
            <Route path="/sales/finances" 
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/FinancesPage'))} />} />
            <Route path="/sales/employee-timesheets"
              element={<ProtectedRoute auth={GuardSales()}
              element={lazy(() => import('./views/sales/EmployeeTimesheetsPage'))} />} />
            {/* END: Sales ***************************************************************** */}
            
            <Route path="/admin/coming-soon/*" element={<AuthComingSoonPage />} />
            <Route path="/account-manager/coming-soon/*" element={<AuthComingSoonPage />} />
            <Route path="/head-of-sales/coming-soon/*" element={<AuthComingSoonPage />} />
            <Route path="/sales/coming-soon/*" element={<AuthComingSoonPage />} />
            <Route path="/employee/coming-soon/*" element={<AuthComingSoonPage />} />

            <Route path="*" element={<AuthPage404 />} />
          </Routes>
        </Suspense>
      </div>
      <AlertPopup />
    </BrowserRouter>
  );
}


// Verify
const VerifySignedIn = async () => {
  let temp = localStorage.getItem(`${APP_PREFIX}_REFRESH`);
  if(!temp) return true;

  let bytes = CryptoJS.AES.decrypt(temp, REFRESH_KEY);
  let _refreshToken = bytes.toString(CryptoJS.enc.Utf8);

  const fetch1 = await fetch(`${API_URL}auth/refresh`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refreshToken: _refreshToken }),
  });
  if(!fetch1.ok || fetch1.status !== 200){
    localStorage.removeItem(`${APP_PREFIX}_USER`);
    localStorage.removeItem(`${APP_PREFIX}_ACCESS`);
    localStorage.removeItem(`${APP_PREFIX}_REFRESH`);
    window.location.reload();
    return false;
  }else{
    let data1 = await fetch1.json();
    let user = CryptoJS.AES.encrypt(JSON.stringify(new UserModel(data1.data.user)), TOKEN_KEY).toString();
    let accessToken = CryptoJS.AES.encrypt(data1.data.accessToken, TOKEN_KEY).toString();
    let refreshToken = CryptoJS.AES.encrypt(data1.data.refreshToken, REFRESH_KEY).toString();
    localStorage.setItem(`${APP_PREFIX}_USER`, user);
    localStorage.setItem(`${APP_PREFIX}_ACCESS`, accessToken);
    localStorage.setItem(`${APP_PREFIX}_REFRESH`, refreshToken);
  }
  
  return true;
};


// Routes
const ProtectedRoute = ({ auth, redirect="/", element: Element }) => {
  if(auth) return <Element />;
  else return <Navigate replace to={redirect} />;
};
const NotSignedInRoute = ({ element: Element }) => {
  let user = getUserModel();
  if(!user.isValid() || !user.isSignedIn()) return <Element />;
  else if(user.isAdmin()) return <Navigate replace to="/admin" />;
  else if(user.isAccountManager()) return <Navigate replace to="/account-manager" />;
  else if(user.isHeadOfSales()) return <Navigate replace to="/head-of-sales" />;
  else if(user.isSales()) return <Navigate replace to="/sales" />;
  else if(user.isEmployee()) return <Navigate replace to="/employee" />;
  else return <Navigate replace to="/no-permission" />;
};


// Guards
const GuardSuperAdmin = () => {
  let user = getUserModel();
  if(!user.isValid()) return false;
  else if(!user.isSuperAdmin()) return false;
  else return true;
};
const GuardAdmin = () => {
  let user = getUserModel();
  if(!user.isValid()) return false;
  else if(!user.isAdmin()) return false;
  else return true;
};
const GuardAccountManager = () => {
  let user = getUserModel();
  if(!user.isValid()) return false;
  else if(!user.isAccountManager()) return false;
  else return true;
};
const GuardHeadOfSales = () => {
  let user = getUserModel();
  if(!user.isValid()) return false;
  else if(!user.isHeadOfSales()) return false;
  else return true;
};
const GuardSales = () => {
  let user = getUserModel();
  if(!user.isValid()) return false;
  else if(!user.isSales()) return false;
  else return true;
};
// const GuardEmployee = () => {
//   let user = getUserModel();
//   if(!user.isValid()) return false;
//   else if(!user.isEmployee()) return false;
//   else return true;
// };


export default App;
