import { FileModel, UserModel, ListsModel } from '.';
import { unescape } from 'html-escaper';

/*
  type : Number
    1 = Income
    2 = Expenses

  status : Number
    0 = Pending
    1 = Rejected
    2 = Approved
    4 = Completed
*/

export class FinanceModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.type = Number(data.type? data.type: 0);

    this.code = data.code? unescape(data.code): null;
    this.project = data.project? unescape(data.project): null; 

    this.user = new UserModel(data.user? data.user: {});
    this.orderAt = data.orderAt? data.orderAt: null;

    this.lists = data.lists && data.lists.length? data.lists.map(d => new ListsModel(d)): [];

    this.total = Number(data.total? data.total: 0);

    this.taxType = Number(data.taxType? data.taxType: 0);
    this.taxName = data.taxName? unescape(data.taxName): null;
    this.taxPercent = Number(data.taxPercent? data.taxPercent: 0);
    this.tax = Number(data.tax? data.tax: 0);

    this.grandTotal = Number(data.grandTotal? data.grandTotal: 0);

    this.note = data.note? unescape(data.note): null;
    this.noteInternal = data.noteInternal? unescape(data.noteInternal): null;
    this.files = data.files && data.files.length? data.files.map(d => new FileModel(d)): [];
    this.sign = Number(data.sign? 1: 0);

    this.status = data.status? data.status: 0;

    this.createdAt = data.createdAt? data.createdAt: null;
    this.createdBy = new UserModel(data.createdBy? data.createdBy: {});
    this.updatedAt = data.updatedAt? data.updatedAt: null;
    this.updatedBy = new UserModel(data.updatedBy? data.updatedBy: {});

    this.approvedBy = new UserModel(data.approvedBy? data.approvedBy: {});
    this.approvedAt = data.approvedAt? data.approvedAt: null;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayType() {
    if(this.isValid()){
      if(this.type === 1) return (<span className="ss-tag bg-success">รายรับ</span>);
      else if(this.type === 2) return (<span className="ss-tag bg-info">รายจ่าย</span>);
      else return (<span className="ss-tag bg-warning">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag bg-warning">รอดำเนินการ</span>);
    }
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 4) return (<span className="ss-tag lg status-4">ดำเนินการแล้ว</span>);
      else if(this.status === 2) return (<span className="ss-tag lg status-2">อนุมัติแล้ว</span>);
      else if(this.status === 1) return (<span className="ss-tag lg status-1">ปฏิเสธ</span>);
      else return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }
  }
}
