import {
  FileModel, ProductCategoryModel,
} from '.';
import { unescape } from 'html-escaper';

/*
  status
    0 = Inactive
    1 = Active
*/

export class ProductModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.category = new ProductCategoryModel(data.category? data.category: {});

    this.sku = data.sku? data.sku: null;

    this.name = data.name? unescape(data.name): null;
    this.shortDescription = data.shortDescription? unescape(data.shortDescription): null;
    this.description = data.description? unescape(data.description): null;
    
    this.keywords = data.keywords? unescape(data.keywords): null;

    this.unit = data.unit? unescape(data.unit): null;

    this.supplierPrice = data.supplierPrice || data.supplierPrice===0 
      ? Number(data.supplierPrice): null;
    this.price = data.price || data.price===0? Number(data.price): null;

    this.shelfLiveDuration = data.shelfLiveDuration || data.shelfLiveDuration===0 
      ? Number(data.shelfLiveDuration): null;
    
    this.image = new FileModel(data.image? data.image: {});
    this.gallery = data.gallery && data.gallery.length
      ? data.gallery.map(d => new FileModel(d)): [];

    this.weight = data.weight? Number(data.weight): null;
    this.width = data.width? Number(data.width): null;
    this.length = data.length? Number(data.length): null;
    this.height = data.height? Number(data.height): null;

    this.order = data.order || data.order===0? Number(data.order): null;
    this.status = data.status? Number(data.status): 0;

    //STOCK
    this.leftQuantity = data.leftQuantity? Math.floor(Number(data.leftQuantity)): 0;
  }
  isValid() {
    return this._id? true: false;
  }

  displayDimension() {
    if(this.isValid()){
      return (<>{this.width} x {this.length} x {this.height} CM<sup>3</sup></>);
    }else{
      return (<></>);
    }
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
}
