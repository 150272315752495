import { UserModel, FileModel, AddressModel } from '.';
import { unescape } from 'html-escaper';

/*
  type :
    0  = บุคคลธรรมดา
    1  = บริษัท

  status :
    -1 = ขอลบบัญชี
    0  = ปิดใช้งาน
    1  = เปิดใช้งาน
*/

export class CustomerModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.user = new UserModel(data.user? data.user: {});

    this.type = data.type? data.type: 0;
    this.code = data.code? data.code: null;
    this.taxId = data.taxId? unescape(data.taxId): null;
    
    this.firstname = data.firstname? unescape(data.firstname): null;
    this.lastname = data.lastname? unescape(data.lastname): null;
    this.username = data.username? data.username: null;
    this.email = data.email? data.email: null;
    this.avatar = new FileModel(
      data.avatar? data.avatar: { path: '/assets/img/default/avatar.jpg' }
    );
  
    this.telephone = data.telephone? data.telephone: null;

    this.fcmToken = data.fcmToken? data.fcmToken: null;
    this.isGuest = data.isGuest? Number(data.isGuest): 0;
    
    this.address = new AddressModel(data.address? data.address: {});

    this.status = data.status? data.status: 0;

    this.website = data.website? data.website: null;
    this.facebook = data.facebook? data.facebook: null;
    this.line = data.line? data.line: null;
    this.google = data.google? data.google: null;
    this.instagram = data.instagram? data.instagram: null;

    this.orderCount = data.orderCount? Number(data.orderCount): 0;
    
    this.createdAt = data.createdAt? data.createdAt: null;
    this.updatedAt = data.updatedAt? data.updatedAt: null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayName() {
    if(this.firstname || this.lastname){
      return `${this?.firstname ?? ''} ${this?.lastname ?? ''}`.trim();
    }else if(this.username) return this.username;
    else return 'Guest Account';
  }
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else if(this.status === -1) return (<span className="ss-tag bg-danger">ขอลบบัญชี</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }

  displayTelephone() {
    if(this.isValid()){
      return this.telephone? this.telephone.replace(/^\+66/, '0'): '-';
    }else{
      return '';
    }
  }

  isSignedIn() {
    return this._id && this.status === 1? true: false;
  }

  isRegistered() {
    return this._id? true: false;
  }
}
