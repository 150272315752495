import { unescape } from 'html-escaper';

export class ShippingAddressModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.addressLocal = data.addressLocal? data.addressLocal: '';
    this.address = data.address? unescape(data.address): '';
    this.subdistrict = unescape(data.subdistrict? data.subdistrict: '');
    this.district = unescape(data.district? data.district: '');
    this.province = unescape(data.province? data.province: '');
    this.country = unescape(data.country? data.country: '');
    this.zipcode = data.zipcode? data.zipcode: '';

    this.lat = data.lat? Number(data.lat): null;
    this.lng = data.lng? Number(data.lng): null;

    this.addressText = data.addressText? unescape(data.addressText): '';

    this.shippingFirstname = data.shippingFirstname? unescape(data.shippingFirstname): '';
    this.shippingLastname = data.shippingLastname? unescape(data.shippingLastname): '';
    this.telephone = data.telephone? data.telephone: '';
    this.instruction = data.instruction? unescape(data.instruction): '';

    this.isPrimary = data.isPrimary? Number(data.isPrimary): 0;
    this.isSelected = data.isSelected? Number(data.isSelected): 0;
  }

  isValid() {
    return this._id? true: false;
  }

  prefixSubdistrict(lang='', ) {
    if(!lang){
      if(!this.province) return 'แขวง / ตำบล';
      else if(this.province.includes('กรุงเทพ')) return 'แขวง';
      else return 'ตำบล';
    }else if(lang === 'TH'){
      if(!this.province) return 'Subdistrict';
      else if(this.province.includes('กรุงเทพ')) return 'subdistrict_1';
      else return 'subdistrict_2';
    }else{
      return 'Subdistrict';
    }
  }
  prefixDistrict(lang='', ) {
    if(!lang){
      if(!this.province) return 'เขต / อำเภอ';
      else if(this.province.includes('กรุงเทพ')) return 'เขต';
      else return 'อำเภอ';
    }else if(lang === 'TH'){
      if(!this.province) return 'District';
      else if(this.province.includes('กรุงเทพ')) return 'district_1';
      else return 'district_2';
    }else{
      return 'District';
    }
  }

  displayPrimary() {
    if(this.isValid()){
      if(this.isPrimary === 1) return (<span className="ss-tag bg-success">ใช่</span>);
      else return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }
  }
  displaySelected() {
    if(this.isValid()){
      if(this.isSelected === 1) return (<span className="ss-tag bg-success">ใช้งานอยู่</span>);
      else return (<span className="ss-tag bg-warning">ไม่ได้ใช้</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่ได้ใช้</span>);
    }
  }
}
