import { UserModel } from '.';
import { unescape } from 'html-escaper';

/*
  status : Number
    0 = ยังไม่ได้พบ
    1 = เข้าพบแล้ว
*/

export class EmployeeTimesheetModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.user = new UserModel(data.user? data.user: {});
    this.startAt = data.startAt? data.startAt: null;

    this.customerName = data.customerName? unescape(data.customerName): null;
    this.telephone = data.telephone? unescape(data.telephone): null;
    this.email = data.email? unescape(data.email): null;

    this.address = data.address? unescape(data.address): null;
    this.note = data.note? unescape(data.note): null;

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เข้าพบแล้ว</span>);
      else return (<span className="ss-tag bg-warning">ยังไม่ได้พบ</span>);
    }else{
      return (<span className="ss-tag bg-warning">ยังไม่ได้พบ</span>);
    }
  }
}
