import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { alertChange } from '../actions/alert.actions';

import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { firebaseAuth } from '../actions/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


function TelephoneInput(props) {
  const countryCodes = ['TH', 'US', 'CN'];

  const [ready, setReady] = useState(true);
	const [value, setValue] = useState(props.value? props.value: '');

  const [telephone, setTelephone] = useState(props.value? props.value: '');
  const [isPossiblePhone, setIsPossiblePhone] = useState(false);
  
  const [state, setState] = useState(0);
  const [codeOTP, setCodeOTP] = useState('');
  const [objectOTP, setObjectOTP] = useState(null);

  const [isModalActive, setIsModalActive] = useState(false);
  const onModalToggle = (e=null, data=false) => {
    if(e) e.preventDefault();
    setReady(true);
    if(!data){
      setTelephone(value);
      setState(0);
      setCodeOTP('');
      setObjectOTP(null);
      setIsModalActive(!isModalActive);
    }else if(state === 0){
      setTelephone(value);
      setState(0);
      setCodeOTP('');
      setObjectOTP(null);
      setIsModalActive(true);
    }
  };

  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const setupRecaptcha = async () => {
    if(recaptchaVerifier){
      recaptchaVerifier.clear();
      setRecaptchaVerifier(null);
    }

    let verifier = new RecaptchaVerifier('recaptcha-container', {}, firebaseAuth);
    verifier.render();
    setRecaptchaVerifier(verifier);

    return await signInWithPhoneNumber(firebaseAuth, telephone, verifier)
      .then(res => {
        setState(2);
        setObjectOTP(res);
      }).catch((err) => {
        // props.alertChange('Warning', 'ไม่สามารถส่ง OTP ได้เนื่องจากเบอร์โทรศัพท์ไม่ถูกต้อง');
        props.alertChange('Warning', err);
        onModalToggle();
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if(ready){
      setReady(false);
      if(value !== telephone && isPossiblePhone){
        try {
          setState(1);
          await setupRecaptcha();
          setReady(true);
        } catch(err) {
          setState(0);
          setReady(true);
          console.log(err);
        }
      }else{
        setReady(true);
      }
    }
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    if(ready){
      setReady(false);
      if(codeOTP && objectOTP){
        try {
          let res = await objectOTP.confirm(codeOTP);
          if(res){
            props.onChange(telephone);
            props.alertChange('Info', 'ยืนยันหมายเลข OTP สำเร็จ');
            onModalToggle();
          }
          setReady(true);
        } catch(err) {
          setReady(true);
          props.alertChange('Warning', 'หมายเลข OTP ไม่ถูกต้อง');
        }
      }else{
        setReady(true);
        props.alertChange('Warning', 'โปรดระบุหมายเลข OTP');
      }
    }
  };

  /* eslint-disable */
	useEffect(() => {
    setValue(props.value? props.value: '');
  }, [props.value]);
	useEffect(() => {
    setIsPossiblePhone(isPossiblePhoneNumber(telephone!==undefined? telephone: ''));
  }, [telephone]);
  /* eslint-enable */

  return (
    <>
      <div onClick={onModalToggle} className={`input-phone ${props.disabled? 'disabled': ''}`}>
        <input 
          type="text" disabled={props.disabled} required={props.required} 
          value={value? value: ''} onChange={() => {}} 
          onFocus={e => onModalToggle(e, true)} 
        />
      </div>

      <div className={`popup-container ${isModalActive? 'active': ''}`}>
        <div className="wrapper">
          {state < 2? (
            <div className="popup-box">
              <div className="popup-header">
                <h6 className="fw-600 lh-xs">เบอร์โทรศัพท์</h6>
                <div className="btn-close" onClick={onModalToggle}>
                  <div className="hamburger active">
                    <div></div><div></div><div></div>
                  </div>
                </div>
              </div>
              <div className="popup-body">
                <PhoneInput 
                  disabled={state !== 0} 
                  defaultประเทศ="TH" 
                  countries={countryCodes} 
                  placeholder="ระบุเบอร์โทรศัพท์" 
                  value={telephone} 
                  onChange={setTelephone} 
                />
                <div className={`mt-4 ${state !== 1? 'd-none': ''}`}>
                  <div id="recaptcha-container"></div>
                </div>
              </div>
              {state === 0? (
                <div className="popup-footer">
                  <div className="btns mt-0">
                    <button 
                      onClick={onSubmit} type="button" 
                      className={`btn btn-action 
                        ${!ready || value === telephone || !isPossiblePhone
                          ? 'btn-default pe-none op-40': 'btn-p'}`} 
                    >
                      ยืนยันเบอร์โทรศัพท์
                    </button>
                    <button onClick={onModalToggle} type="button" className="btn btn-action btn-default">
                      ปิด
                    </button>
                  </div>
                </div>
              ): (<></>)}
            </div>
          ): (
            <div className="popup-box">
              <div className="popup-header">
                <h6 className="fw-600 lh-xs">ยืนยันเลข OTP</h6>
                <div className="btn-close" onClick={onModalToggle}>
                  <div className="hamburger active">
                    <div></div><div></div><div></div>
                  </div>
                </div>
              </div>
              <div className="popup-body">
                <p className="fw-500">กรุณายืนยันเลข OTP ที่ส่งไปยังหมายเลขโทรศัพท์</p>
                <p className="lg fw-600">{telephone? telephone: ''}</p>
                <div className="form-control mt-2">
                  <input
                    type="text" required={true} 
                    value={codeOTP? codeOTP: ''} 
                    onChange={e => setCodeOTP(e.target.value)} 
                  />
                  {/* <p className="xs fw-400 mt-2">
                    <span onClick={onResendOTP} className="op-80 c-pointer h-color-p">
                      <u>ส่งเลข OTP อีกครั้ง</u>
                    </span>
                  </p> */}
                </div>
              </div>
              <div className="popup-footer">
                <div className="btns mt-0">
                  <button 
                    onClick={onSubmitOTP} type="button" 
                    className={`btn btn-action ${!ready? 'btn-default pe-none op-40': 'btn-p'}`} 
                  >
                    ยืนยันเลข OTP
                  </button>
                  <button 
                    onClick={onModalToggle} type="button" 
                    className={`btn btn-action btn-default ${!ready? 'pe-none op-40': ''}`} 
                  >
                    ปิด
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

TelephoneInput.defaultProps = {
  disabled: false,
  required: false,
  value: '',
  onChange: () => {}
};
TelephoneInput.propTypes = {
  alertChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

const mapStateToProps = (state) => ({
	
});

export default connect(mapStateToProps, {
  alertChange: alertChange
})(TelephoneInput);