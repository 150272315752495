import { unescape } from 'html-escaper';

export class FileModel {
  constructor(data={}) {
    this.originalname = data.originalname? unescape(data.originalname): null;
    this.mimetype = data.mimetype? data.mimetype: null;
    this.filename = data.filename? data.filename: null;
    this.size = data.size? data.size: null;
    this.path = null;
    if(data.path){
      if(!data.path.includes('http://') && !data.path.includes('https://')){
        this.path = '/assets/img/default/avatar.jpg';
      }else{
        this.path = data.path.replace('http://localhost:4700/', 'https://cdn.warmthtreehouse.co.th/');
      }
    }
  }

  isValid() {
    return this.path && this.size && this.filename && this.mimetype && this.originalname? true: false;
  }

  previewSrc() {
    if(['image/png'].indexOf(this.mimetype) > -1){
      return '/assets/images/file/png.svg';
    }else if(['image/jpeg', 'image/jpg'].indexOf(this.mimetype) > -1){
      return '/assets/images/file/jpg.svg';
    }else if(['application/pdf'].indexOf(this.mimetype) > -1){
      return '/assets/images/file/pdf.svg';
    }else if(['video/mp4'].indexOf(this.mimetype) > -1){
      return '/assets/images/file/mp4.svg';
    }else{
      return '';
    }
  }
  displaySize() {
    if(this.size > 10**9) return `${Math.round(this.size / 10**9)} GB`;
    else if(this.size > 10**6) return `${Math.round(this.size / 10**6)} MB`;
    else if(this.size > 10**3) return `${Math.round(this.size / 10**3)} KB`;
    else return `${this.size} B`;
  }
}
