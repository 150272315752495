import { FileModel } from '.';
import { unescape } from 'html-escaper';

/*
  status :
    0 = Inactive
    1 = Active
*/

export class CurrencyModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.code = data.code? data.code: '';
    this.icon = data.icon? data.icon: '';
    this.unit = data.unit? data.unit: '';

    this.name = data.name? unescape(data.name): '';
    this.exchangeRate = data.exchangeRate? Number(data.exchangeRate): 1;

    this.image = new FileModel(data.image? data.image: {});
    
    this.isDefault = data.isDefault? Number(data.isDefault): 0;
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;
  }
  isValid() {
    return this._id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
  displayDefault() {
    if(this.isValid()){
      if(this.isDefault === 1) return (<span className="ss-tag bg-success">ใช่</span>);
      else return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }
  }
}
