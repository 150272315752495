import {
  CUSTOMERS, 
  CUSTOMER_PR, CUSTOMER_PO, CUSTOMER_INVOICE, CUSTOMER_RECEIPT,
  CUSTOMER_SHIPPING_ADDRESSES, CUSTOMER_BILLING_ADDRESSES,
  CUSTOMER_ORDERS,
} from '../actions/types';

const initialState = {
  list: [],

  customerPr: [],
  customerPo: [],
  customerInvoice: [],
  customerReceipt: [],

  shippingAddresses: [],
  billingAddresses: [],
  points: [],
  orders: [],
  pushNotis: []
};

const customerReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case CUSTOMERS:
      return { ...state, list: action.payload };
    case CUSTOMER_SHIPPING_ADDRESSES:
      return { ...state, shippingAddresses: action.payload };
    case CUSTOMER_BILLING_ADDRESSES:
      return { ...state, billingAddresses: action.payload };

    case CUSTOMER_PR:
      return { ...state, customerPr: action.payload };
    case CUSTOMER_PO:
      return { ...state, customerPo: action.payload };
    case CUSTOMER_INVOICE:
      return { ...state, customerInvoice: action.payload };
    case CUSTOMER_RECEIPT:
      return { ...state, customerReceipt: action.payload };  
    case CUSTOMER_ORDERS:
      return { ...state, orders: action.payload };

    default:
      return state;
  }
};

export default customerReducer;