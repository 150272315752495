import {
  FileModel, SupplierModel, UserModel, CurrencyModel, ProductsModel
} from '.';
import { unescape } from 'html-escaper';

/*
  discountType : Number
    1 = Percentage
    2 = Custom

  taxType : Number
    0 = No tax
    1 = Products included tax
    2 = Products excluded tax

  status : Number
    0 = Pending
    1 = Rejected
    2 = Approved
    4 = Completed
*/

export class SupplierPrModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.supplier = new SupplierModel(data.supplier? data.supplier: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.code = data.code? unescape(data.code): null;
    this.project = data.project? unescape(data.project): null; 

    this.supplierName = data.supplierName? unescape(data.supplierName): null;
    this.supplierTaxId = data.supplierTaxId? data.supplierTaxId: null;
    this.supplierAddress = data.supplierAddress? unescape(data.supplierAddress): null;
    this.supplierZipcode = data.supplierZipcode? data.supplierZipcode: null;

    this.orderAt = data.orderAt? data.orderAt: null;
    this.creditDuration = Number(data.creditDuration? data.creditDuration: 0);

    this.currency = new CurrencyModel(data.currency? data.currency: {});
    this.currencyId = data.currencyId? data.currencyId: null;
    this.exchangeRate = Number(data.exchangeRate? data.exchangeRate: 1);

    this.products = data.products && data.products.length 
      ? data.products.map(d => new ProductsModel(d)): [];

    this.total = Number(data.total? data.total: 0);

    this.discountType = Number(data.discountType? data.discountType: 1);
    this.discountPercent = Number(data.discountPercent? data.discountPercent: 0);
    this.discount = Number(data.discount? data.discount: 0);

    this.taxType = Number(data.taxType? data.taxType: 0);
    this.taxName = data.taxName? unescape(data.taxName): null;
    this.taxPercent = Number(data.taxPercent? data.taxPercent: 0);
    this.tax = Number(data.tax? data.tax: 0);

    this.grandTotal = Number(data.grandTotal? data.grandTotal: 0);

    this.note = data.note? unescape(data.note): null;
    this.noteInternal = data.noteInternal? unescape(data.noteInternal): null;

    this.sign = Number(data.sign? 1: 0);

    this.gallery = data.gallery && data.gallery.length
    ? data.gallery.map(d => new FileModel(d)): [];

    this.status = data.status? data.status: 0;

    this.createdAt = data.createdAt? data.createdAt: null;
    this.createdBy = new UserModel(data.createdBy? data.createdBy: {});
    this.updatedAt = data.updatedAt? data.updatedAt: null;
    this.updatedBy = new UserModel(data.updatedBy? data.updatedBy: {});

    this.approvedBy = new UserModel(data.approvedBy? data.approvedBy: {});
    this.approvedAt = data.approvedAt? data.approvedAt: null;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 4) return (<span className="ss-tag lg status-4">ดำเนินการแล้ว</span>);
      else if(this.status === 2) return (<span className="ss-tag lg status-2">อนุมัติแล้ว</span>);
      else if(this.status === 1) return (<span className="ss-tag lg status-1">ปฏิเสธ</span>);
      else return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }
  }

  isValidAddress() {
    if(this.isValid()){
      return this.address && this.subdistrict.isValid() && this.district.isValid() 
        && this.province.isValid() && this.country.isValid() && this.zipcode;
    }else{
      return false;
    }
  }

  prefixSubdistrict() {
    if(!this.province) return 'แขวง / ตำบล';
    else if(this.province.includes('กรุงเทพ')) return 'แขวง';
    else return 'ตำบล';
  }
  prefixDistrict() {
    if(!this.province) return 'เขต / อำเภอ';
    else if(this.province.includes('กรุงเทพ')) return 'เขต';
    else return 'อำเภอ';
  }
}
