import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store';

import 'swiper/css';
import 'react-toastify/dist/ReactToastify.css';

const AppWithRouter = () => (
  <Provider store={store}>
    <App />
  </Provider>
)
ReactDOM.render(<AppWithRouter />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
