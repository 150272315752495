export const APP_YEAR = Number(process.env.REACT_APP_YEAR? process.env.REACT_APP_YEAR: 2024);

export const APP_URL = `${process.env.REACT_APP_URL}`;
export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const FRONTEND_URL = `${process.env.REACT_APP_FRONTEND_URL}`;

export const APP_NAME = `${process.env.REACT_APP_NAME? process.env.REACT_APP_NAME: ''}`;
export const APP_SHORT_NAME = `${process.env.REACT_APP_SHORT_NAME? process.env.REACT_APP_SHORT_NAME: ''}`;
export const APP_LOGO = `${process.env.REACT_APP_LOGO? process.env.REACT_APP_LOGO: ''}`;

export const CDN_URL = `${process.env.REACT_APP_CDN_URL}`;
export const CDN_PUBLIC_KEY = `${process.env.REACT_APP_CDN_PUBLIC_KEY}`;

export const APP_PREFIX = `${process.env.REACT_APP_PREFIX}`;

export const TOKEN_KEY = `${process.env.REACT_APP_TOKEN_KEY}`;
export const REFRESH_KEY = `${process.env.REACT_APP_REFRESH_KEY}`;

export const FIREBASE_CONFIG = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};
