import { unescape } from 'html-escaper';

export class AddressModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.addressLocal = data.addressLocal? data.addressLocal: '';
    this.address = data.address? unescape(data.address): '';
    this.subdistrict = unescape(data.subdistrict? data.subdistrict: '');
    this.district = unescape(data.district? data.district: '');
    this.province = unescape(data.province? data.province: '');
    this.country = unescape(data.country? data.country: '');
    this.zipcode = data.zipcode? data.zipcode: '';

    this.lat = data.lat? Number(data.lat): null;
    this.lng = data.lng? Number(data.lng): null;

    this.addressText = data.addressText? unescape(data.addressText): '';

    this.telephone = data.telephone? data.telephone: '';
    this.instruction = data.instruction? unescape(data.instruction): '';

    this.isPrimary = data.isPrimary? Number(data.isPrimary): 0;
    this.isSelected = data.isSelected? Number(data.isSelected): 0;
  }

  isValid() {
    return this._id? true: false;
  }
  isValidAddress() {
    if(this.isValid()){
      return this.address && this.subdistrict.isValid() && this.district.isValid() 
        && this.province.isValid() && this.country.isValid() && this.zipcode;
    }else{
      return false;
    }
  }

  prefixSubdistrict() {
    if(!this.province) return 'แขวง / ตำบล';
    else if(this.province.includes('กรุงเทพ') 
    || this.province.toLowerCase().includes('bangkok')) return 'แขวง';
    else return 'ตำบล';
  }
  prefixDistrict() {
    if(!this.province) return 'เขต / อำเภอ';
    else if(this.province.includes('กรุงเทพ') 
    || this.province.toLowerCase().includes('bangkok')) return 'เขต';
    else return 'อำเภอ';
  }
  
  display(withZipcode=true) {
    let result = '', sep = '';
    if(this.address){
      result += `${sep}${this.address}`; sep = '\n';
    }
    if(this.subdistrict){
      result += `${sep}${this.prefixSubdistrict()}${this.subdistrict}`; sep = ' ';
      if(this.district) result += `${sep}${this.prefixDistrict()}${this.district}`;
      sep = '\n';
    }else if(this.district){
      result += `${sep}${this.prefixDistrict()}${this.district}`; sep = '\n';
    }
    if(this.province){
      result += `${sep}จังหวัด${this.province}`; sep = ' ';
      if(this.country) result += `${sep}ประเทศ${this.country}`;
      sep = ' ';
    }else if(this.country){
      result += `${sep}ประเทศ${this.country}`; sep = ' ';
    }
    if(withZipcode && this.zipcode){
      result += `${sep}${this.zipcode}`; sep = '';
    }
    return result.trim();
  }
}
