import { connect } from 'react-redux';
import { appCopyright } from '../helpers/frontend';

function Footer(props) {
  return !Object.keys(props.settings).length? (<></>): (
    <footer className="footer bcolor-fgray color-dark">
      <div className="container">
        <p className="sm fw-500 text-center">
          {appCopyright(props.settings['SYSTEM_COMPANY_NAME'])} <span className="xs-hide">All rights reserved.</span>
        </p>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  
};
Footer.propTypes = {
	
};

const mapStateToProps = (state) => ({
  settings: state.app.settings,
});

export default connect(mapStateToProps, {

})(Footer);