import { UserModel } from '.';

export class ListsModel {
  constructor(data={}) {
    this.user = new UserModel(data.user && typeof data.user !== 'string'? data.user: {});
    this.name = data.name? data.name: null;
    this.quantity = data.quantity || data.quantity===0? Number(data.quantity): null;
    this.unit = data.unit? data.unit: null;
    this.price = data.price || data.price===0? Number(data.price): null;
  }
}
