import { ProductModel, ProductUnitModel, } from '.';

export class ProductsModel {
  constructor(data={}) {
    this.product = new ProductModel(data.product && typeof data.product !== 'string'? data.product: {});
    this.productUnit = new ProductUnitModel(data.productUnit && typeof data.productUnit !== 'string'? data.productUnit: {});

    this.quantity = data.quantity || data.quantity===0? Number(data.quantity): null;
    this.price = data.price || data.price===0? Number(data.price): null;
    
    this.leftQuantity = data.leftQuantity || data.leftQuantity===0? Number(data.leftQuantity): null;

    this.shelfLiveDuration = Number(data?.shelfLiveDuration ?? 0);
    this.expiredAt = data.expiredAt? data.expiredAt: null;

    this.stockOut = data.stockOut && data.stockOut.length ? data.stockOut: [];
  }
}
