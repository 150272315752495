import { UserRoleModel, FileModel, AddressModel } from '.';
import { unescape } from 'html-escaper';

export class UserModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.role = new UserRoleModel(data.role? data.role: {});

    this.relatedUser = data.relatedUser? data.relatedUser: null;
    
    this.firstname = data.firstname? unescape(data.firstname): null;
    this.lastname = data.lastname? unescape(data.lastname): null;
    this.username = data.username? data.username: null;
    this.email = data.email? data.email: null;
    this.avatar = new FileModel(
      data.avatar? data.avatar: { path: '/assets/img/default/avatar.jpg' }
    );

    this.address = new AddressModel(data.address? data.address: {});

    this.jobTitle = data.jobTitle? unescape(data.jobTitle): null;
    this.salary = data.salary || data.salary===0? Number(data.salary): null;
    this.socialId = data.socialId? unescape(data.socialId): null;

    this.monthlyVisitTarget = data.monthlyVisitTarget || data.monthlyVisitTarget===0 
      ? Number(data.monthlyVisitTarget): 0;
    this.monthlySalesTarget = data.monthlySalesTarget || data.monthlySalesTarget===0 
      ? Number(data.monthlySalesTarget): 0;

    this.files = data.files && data.files.length? data.files.map(d => new FileModel(d)): [];
    
    this.fcmToken = data.fcmToken? data.fcmToken: null;
    this.status = data.status? data.status: 0;
  }

  isValid() { return this._id? true: false; }

  displayName() {
    if(this.firstname || this.lastname) return this.firstname+' '+this.lastname;
    else if(this.username) return this.username;
    else return '';
  }
  displayRole() {
    return this.role.displayName();
  }
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }

  isSignedIn() { return this._id && this.status === 1? true: false; }

  isSuperAdmin() { return this.role.isValid() && [99].indexOf(this.role.level) > -1? true: false; }
  isAdmin() { return this.role.isValid() && [98, 99].indexOf(this.role.level) > -1? true: false; }
  isAccountManager() { return this.role.isValid() && [20].indexOf(this.role.level) > -1? true: false; }
  isHeadOfSales() { return this.role.isValid() && [10].indexOf(this.role.level) > -1? true: false; }
  isSales() { return this.role.isValid() && [2].indexOf(this.role.level) > -1? true: false; }
  isEmployee() { return this.role.isValid() && [1].indexOf(this.role.level) > -1? true: false; }

  path() {
    if(this.isSignedIn()){
      if(this.isAdmin()) return '/admin';
      else if(this.isAccountManager()) return '/account-manager';
      else if(this.isHeadOfSales()) return '/head-of-sales';
      else if(this.isSales()) return '/sales';
      else if(this.isEmployee()) return '/employee';
      else return '';
    }else{
      return '';
    }
  }
}
