import { APP_PREFIX } from '../actions/variables';
import {
  USER_SIGNIN, USER_SIGNOUT, USER_UPDATE, USER_PERMISSIONS, USER_FILES
} from '../actions/types';
import { UserModel } from '../models';

import CryptoJS from 'crypto-js';
import { getUserModel } from '../helpers/header';
import { TOKEN_KEY } from '../actions/variables';

var _user;
const initialState = {
  user: getUserModel(),
  accessToken: null,
  refreshToken: null,
  permissions: [],
  files: []
};

const userReducer = (state = initialState, action) => {
  switch(action.type) {

    case USER_SIGNIN:
      _user = new UserModel(action.payload.user);
      localStorage.setItem(
        `${APP_PREFIX}_USER`,
        CryptoJS.AES.encrypt(JSON.stringify(_user), TOKEN_KEY).toString(),
      );
      localStorage.setItem(`${APP_PREFIX}_ACCESS`, action.payload.accessToken);
      localStorage.setItem(`${APP_PREFIX}_REFRESH`, action.payload.refreshToken);
      return {
        ...state,
        user: _user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case USER_SIGNOUT:
      _user = new UserModel();
      localStorage.removeItem(`${APP_PREFIX}_USER`);
      localStorage.removeItem(`${APP_PREFIX}_ACCESS`);
      localStorage.removeItem(`${APP_PREFIX}_REFRESH`);
      return {
        ...state,
        user: _user,
        accessToken: null,
        refreshToken: null,
        permissions: []
      };

    case USER_UPDATE:
      _user = getUserModel();
      if(action.payload.firstname) _user.firstname = action.payload.firstname;
      if(action.payload.lastname) _user.lastname = action.payload.lastname;
      if(action.payload.username) _user.username = action.payload.username;
      if(action.payload.email) _user.email = action.payload.email;
      if(action.payload.avatar) _user.avatar = action.payload.avatar;
      if(action.payload.address) _user.address = action.payload.address;
      localStorage.setItem(
        `${APP_PREFIX}_USER`,
        CryptoJS.AES.encrypt(JSON.stringify(_user), TOKEN_KEY).toString(),
      );
      return { ...state, user: _user };

    case USER_PERMISSIONS:
      return { ...state, permissions: action.payload };

    case USER_FILES:
      return { ...state, files: action.payload };

    default:
      return state;
  }
};

export default userReducer;