import { combineReducers } from 'redux';
import alertReducer from './alert.reducer';
import appReducer from './app.reducer';
import userReducer from './user.reducer';
import productReducer from './product.reducer';
import supplierReducer from './supplier.reducer';
import stockReducer from './stock.reducer';
import customerReducer from './customer.reducer';
import financeReducer from './finance.reducer';

export default combineReducers({
  alert: alertReducer,
  app: appReducer,
  user: userReducer,
  product: productReducer,
  supplier: supplierReducer,
  stock: stockReducer,
  customer: customerReducer,
  finance: financeReducer,
});