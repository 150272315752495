import { ALERT_LOADING } from './types';
import { toast, Bounce } from 'react-toastify';

export const alertChange = (type, message, errors=[]) => (dispatch) => {
  const messageHtml = (
    <>
      <p>{message}</p>
      {Object.keys(errors).length? (
        <ul className="m-0">
          {Object.keys(errors).map((k, i) => (
            <li key={`e_${i}`} className="sm lh-sm m-0">{errors[k]}</li>
          ))} 
        </ul>
      ): (<></>)}
    </>
  );
  let options = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Bounce,
  };
  if(type === 'Info'){
    toast.info(messageHtml, options);
  }else if(type === 'Success'){
    toast.success(messageHtml, options);
  }else if(type === 'Warning'){
    toast.warning(messageHtml, options);
  }else if(type === 'Danger'){
    toast.error(messageHtml, options);
  }
  dispatch({ type: ALERT_LOADING, payload: false });
};

export const alertLoading = (loading) => (dispatch) => {
  dispatch({ type: ALERT_LOADING, payload: loading });
};
