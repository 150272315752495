import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { onMounted, appName, appLogo, appBg, appCopyright } from '../../helpers/frontend';
import TelephoneInput from '../../components/TelephoneInput';
import PasswordValidator from '../../components/PasswordValidator';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { processRead } from '../../actions/frontend.actions';
import { customerSignup } from '../../actions/user.actions';
import { CustomerModel, SupplierModel, UserModel } from '../../models';

function CustomerSignupQrCodePage(props) {
  const history = useNavigate();
  const params = useParams();
  const partnerShopCode = params.partnerShopCode? params.partnerShopCode: null;
  const salesManagerId = params['*']? params['*']: null;

  const [state, setState] = useState(0);

  const [partnerShop, setPartnerShop] = useState(new SupplierModel());
  const [salesManager, setSalesManager] = useState(new UserModel());

  const [values, setValues] = useState(new CustomerModel({ status: 1 }));
  const onChangeInput = (key, val, isNumber=false) => {
    if(isNumber) val = val || val===0? Number(val): '';
    setValues(new CustomerModel({ ...values, [key]: val }));
  };
  const onChangeTelephone = (val) => {
    onChangeInput('telephone', val);
  };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const [isModalActive, setIsModalActive] = useState(false);
  const onModalToggle = (e=null, data=false) => {
    if(e) e.preventDefault();
    if(!data) setIsModalActive(!isModalActive);
    else setIsModalActive(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let res = await props.customerSignup({
      ...values,
      password: password, confirmPassword: confirmPassword,
      partnerShopId: partnerShop._id,
      salesManagerId: salesManager._id
    }, true);
    if(res) onModalToggle();
  };
  
  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
	useEffect(() => {
    const onPreload = async () => {
      if(partnerShopCode && partnerShopCode !== 'none'){
        await props.processRead('partner-shop', { code: partnerShopCode }, true).then(d => {
          setPartnerShop(d);
          setState(1);
        }).catch(() => history('/'));
      }else setState(1);
      if(salesManagerId){
        await props.processRead('sales-manager', { _id: salesManagerId }, true).then(d => {
          setSalesManager(d);
        });
      }
    };
    onPreload();
  }, []);
  /* eslint-enable */

  return !Object.keys(props.settings).length? (<></>): (
    <>
      <section className="auth-01 section-padding">
        <div className="bg-img" style={{ backgroundImage: `url('${appBg(props.settings['SYSTEM_BG'])}')` }}></div>
        <div className="container">
          {state === 1? (
            <div className="auth-container auth-container-lg bradius box-shadow">
              <div className="auth-body bg-white">
                <div className="title">
                  <img className="logo" src={appLogo(props.settings['SYSTEM_LOGO'])} alt="Logo" />
                  <div className="text">
                    <h5 className="fw-900 lh-sm">Register New Customer</h5>
                    <p className="fw-600 op-70">{appName()}</p>
                  </div>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="grids mt-2">
                    {partnerShop.isValid()? (
                      <div className="grid sm-100">
                        <div className="form-control">
                          <label>Partner Shop</label>
                          <input
                            type="text" disabled={true} 
                            value={partnerShop.isValid()? partnerShop.name: ''} 
                          />
                        </div>
                      </div>
                    ): (<></>)}
                    {false && salesManager.isValid()? (
                      <div className="grid sm-100">
                        <div className="form-control">
                          <label>ผู้จัดการฝ่ายขาย</label>
                          <input
                            type="text" disabled={true} 
                            value={salesManager.isValid()? salesManager.displayName(): ''} 
                          />
                        </div>
                      </div>
                    ): (<></>)}
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>ชื่อจริง <span className="color-danger">*</span></label>
                        <input
                          type="text" required={true} 
                          value={values.firstname? values.firstname: ''} 
                          onChange={e => onChangeInput('firstname', e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>นามสกุล <span className="color-danger">*</span></label>
                        <input
                          type="text" required={true} 
                          value={values.lastname? values.lastname: ''} 
                          onChange={e => onChangeInput('lastname', e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="sep"></div>
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>เบอร์โทรศัพท์ <span className="color-danger">*</span></label>
                        <TelephoneInput 
                          disabled={false} required={true} 
                          value={values.telephone? values.telephone: ''} 
                          onChange={onChangeTelephone} 
                        />
                      </div>
                    </div>
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>อีเมล</label>
                        <input
                          type="email" required={false} 
                          value={values.email? values.email: ''} 
                          onChange={e => onChangeInput('email', e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="sep"></div>
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>รหัสผ่าน <span className="color-danger">*</span></label>
                        <input
                          type="password" 
                          value={password} required={true} 
                          onChange={e => setPassword(e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="grid sm-50">
                      <div className="form-control">
                        <label>ยืนยันรหัสผ่าน <span className="color-danger">*</span></label>
                        <input
                          type="password" 
                          value={confirmPassword} required={true} 
                          onChange={e => setConfirmPassword(e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="grid sm-100">
                      <PasswordValidator password={password} />
                    </div>
                  </div>
                  <div className="btns pt-1">
                    <button type="submit" className="btn btn-action btn-p btn-lg w-full">
                      ลงทะเบียน
                    </button>
                  </div>
                </form>
              </div>
              <div className="auth-footer bg-s color-white">
                <p className="xxs fw-300 text-center">
                  {appCopyright(props.settings['SYSTEM_COMPANY_NAME'])}
                </p>
              </div>
            </div>
          ): (<></>)}
        </div>
      </section>
      
      <div className={`popup-container ${isModalActive? 'active': ''}`}>
        <div className="wrapper">
          <div className="popup-box">
            <div className="popup-header">
              <h6 className="fw-600 lh-xs">ลงทะเบียนสำเร็จ</h6>
            </div>
            <div className="popup-body">
              <p className="fw-500">คุณสามารถเข้าใช้งานแอปพลิเคชันได้ โดยใช้ข้อมูลดังต่อไปนี้</p>
              <table className="table mt-4">
                <tbody>
                  <tr>
                    <td className="ws-nowrap fw-600 border-top-1 bcolor-gray">เบอร์โทรศัพท์ :</td>
                    <td className="ws-nowrap border-top-1 bcolor-gray" style={{ width: '100%' }}>
                      {values.telephone? values.telephone.replace(/^\+66/, '0'): ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="ws-nowrap fw-600">รหัสผ่าน :</td>
                    <td className="ws-nowrap" style={{ width: '100%' }}>
                      {password? password: ''}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="grids mt-2">
                {props.settings['MOBILE_APP_STORE_URL']? (
                  <div className="grid sm-50 xs-50">
                    <a href={props.settings['MOBILE_APP_STORE_URL']} target="_blank" rel="noreferrer">
                      <img className="img" src="/assets/img/icon/btn-app-store.png" alt="App Store Button" />
                    </a>
                  </div>
                ): (<></>)}
                {props.settings['MOBILE_GOOGLE_PLAY_URL']? (
                  <div className="grid sm-50 xs-50">
                    <a href={props.settings['MOBILE_GOOGLE_PLAY_URL']} target="_blank" rel="noreferrer">
                      <img className="img" src="/assets/img/icon/btn-google-play.png" alt="Google Play Button" />
                    </a>
                  </div>
                ): (<></>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CustomerSignupQrCodePage.defaultProps = {
	
};
CustomerSignupQrCodePage.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
	processRead: PropTypes.func.isRequired,
  customerSignup: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({

  settings: state.app.settings,
});

export default connect(mapStateToProps, {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processRead: processRead,
  customerSignup: customerSignup
})(CustomerSignupQrCodePage);