import { unescape } from 'html-escaper';
import { UserModel } from '.';

export class UserFileModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.user = new UserModel(data.user? data.user: {}); 

    this.filename = data.filename? data.filename: null;
    this.mimetype = data.mimetype? data.mimetype: null;
    this.originalname = data.originalname? unescape(data.originalname): null;
    this.path = null;
    this.size = data.size? data.size: null;
    if(data.filename){
      if(data.filename.includes('files/resized-file-')){
        this.filename = data.filename.replace('files/resized-file-', '');
      }
    }
    if(data.path){
      if(!data.path.includes('http://') && !data.path.includes('https://')){
        this.path = '/assets/img/default/avatar.jpg';
      }else{
        this.path = data.path.replace('http://localhost:4700/', 'https://cdn.warmthtreehouse.co.th/');
      }
    }

    this.createdBy = new UserModel(data.createdBy? data.createdBy: {}); 
    this.updatedBy = new UserModel(data.updatedBy? data.updatedBy: {}); 

    this.createdAt = data.createdAt? data.createdAt: null;
    this.updatedAt = data.updatedAt? data.updatedAt: null;
  }

  isValid() {
    return this.path && this.size && this.filename && this.mimetype && this.originalname? true: false;
  }

  previewSrc() {
    if(['image/png'].indexOf(this.mimetype) > -1){
      return '/assets/img/file/png.svg';
    }else if(['image/jpeg', 'image/jpg'].indexOf(this.mimetype) > -1){
      return '/assets/img/file/jpg.svg';
    }else if(['application/pdf'].indexOf(this.mimetype) > -1){
      return '/assets/img/file/pdf.pdf';
    }else if(['video/mp4'].indexOf(this.mimetype) > -1){
      return '/assets/img/file/mp4.svg';
    }else{
      return '';
    }
  }
  displaySize() {
    if(this.size > 10**9) return `${Math.round(this.size / 10**9)} GB`;
    else if(this.size > 10**6) return `${Math.round(this.size / 10**6)} MB`;
    else if(this.size > 10**3) return `${Math.round(this.size / 10**3)} KB`;
    else return `${this.size} B`;
  }
}
