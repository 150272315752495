import moment from 'moment';
import { unescape } from 'html-escaper';
import {
  APP_LOGO, APP_NAME, APP_SHORT_NAME,
} from '../actions/variables';


export const appLogo = (obj=null, fallback='/assets/img/logo.png') => {
  if(obj && typeof obj === 'string') obj = JSON.parse(unescape(obj));
  if(APP_LOGO) return APP_LOGO;
  else if(obj && obj.path) return obj.path;
  else return fallback;
};
export const appBg = (obj=null, fallback='/assets/img/bg/04.jpg') => {
  if(obj && typeof obj === 'string') obj = JSON.parse(unescape(obj));
  if(obj && obj.path) return obj.path;
  else return fallback;
};
export const appName = (name, fallback='Warth Tree House') => {
  if(APP_NAME) return APP_NAME;
  else if(name) return name;
  else return fallback;
};
export const appShortName = (name, fallback='Warth Tree House') => {
  if(APP_SHORT_NAME) return APP_SHORT_NAME;
  else if(name) return name;
  else return fallback;
};
export const appCopyright = (cname='') => {
  cname = cname? cname: 'Warmth Tree House Company Limited';
  let year = moment(new Date()).format('Y');
  let copyright = `© 2024${year !== '2024'? '-'+year: ''} ${cname}`;
  if(copyright[copyright.length-1] !== '.') copyright += '.';
  return copyright;
};

export const checkEnabled = (moduleKeys=[], settings={}) => {
  if(!moduleKeys.length) return true;
  else{
    let keys = Object.keys(settings);
    if(!keys.length) return true;
    else{
      let allow = true;
      moduleKeys.forEach(k => { if(!settings[k] || settings[k] !== '1') allow = false; });
      if(!allow) window.location = '/';
      return allow;
    }
  }
};
export const onMounted = () => {
  return true;
};

export const scrollToRef = (ref) => {
  if(ref && ref.current){
    window.scrollTo({ top: ref.current.offsetTop - 82, left: 0, behavior: 'smooth' });
  }
};
export const scrollToBottomRef = (ref) => {
  if(ref && ref.current){
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
};

export const formatNumber = (value, digits=2) => {
  let val = (value / 1).toFixed(digits);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatDate = (value=null, format='DD/MM/YYYY', thai=true) => {
  let string;
  if(!value) string = moment(new Date()).format(format);
  else string = moment(new Date(String(value))).format(format);
  if(string) {
    if(thai) {
      string = string.split('/');
      if(string.length < 3){
        return `${string[0]}/${parseInt(string[1])+543}`;
      }else{
        return `${string[0]}/${string[1]}/${parseInt(string[2])+543}`;
      }
    } else {
      return string;
    }
  } else {
    return '';
  }
};
export const formatTime = (value=null, format='hh:mm A') => {
  let string;
  if(!value) string = moment(new Date()).format(format);
  else string = moment(new Date(String(value))).format(format);
  if(string) {
    return string;
  } else {
    return '';
  }
};
export const formatSeconds = (sec) => {
  if(sec) {
    sec = Math.round(sec);
    let hours = Math.floor(sec/3600);
    (hours >= 1)? sec -= (hours*3600): hours = '00';
    let min = Math.floor(sec/60);
    (min >= 1)? sec -= (min*60): min = '00';
    (sec < 1)? sec = '00': void 0;
  
    (min.toString().length === 1)? min = '0'+min: void 0;    
    (sec.toString().length === 1)? sec = '0'+sec: void 0;    
    
    return hours+':'+min+':'+sec;
  } else {
    return '00:00:00';
  }
};
export const formatTextSecret = (str, showDigits=3) => {
  if(str) {
    let len = str.length;
    let res = [...str].map((d, i) => {
      if(i < len - showDigits) return '*';
      else return d;
    }).join('');
    return (<span title={str}>{res}</span>);
  } else {
    return (<></>);
  }
};

export const displayDay = (i, ) => {
  let days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
  let temp = [ days[Number(i)].toLowerCase() ];
  if(temp) return temp;
  else{
    days = [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์' ];
    return days[Number(i)];
  }
};
export const displayMonth = (i) => {
  let months = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฏาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
  ];
  return months[Number(i)];
};

export const diffDays = (fromDate=null) => {
  let today = new Date();
  let fromDay = fromDate? new Date(fromDate): new Date();

  let firstDateInMs = today.getTime()
  let secondDateInMs = fromDay.getTime()

  let differenceBtwDates = secondDateInMs - firstDateInMs

  let aDayInMs = 24 * 60 * 60 * 1000

  let daysDiff = Math.round(differenceBtwDates / aDayInMs)
  return daysDiff;
};
export const nextDays = (n=0, fromDate=null) => {
  let today = fromDate? new Date(fromDate): new Date();
  return new Date(today.setDate(today.getDate() + n));
};
export const nexHours = (n=0) => {
  let today = new Date();
  return new Date(today.setHours(today.getHours() + n));
};
export const isToday = (date) => {
  let today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
};

export const hex2rgb = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16), 
    g: parseInt(hex.slice(3, 5), 16), 
    b: parseInt(hex.slice(5, 7), 16)
  };
};

export const digitsCeil = (val) => {
  let digits = `${Math.round(val)}`.length - 1;
  let res = Math.ceil(val / 10**digits) * 10**digits;
  if((res - val) / 10**digits > 0.5){
    return res - 5 * 10**(digits-1);
  }else{
    return res;
  }
};

export const textToCapital = (text) => {
  if(!text) return '';
  text = `${text}`;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const nl2br = (str, is_xhtml) => {
  if(typeof str === 'undefined' || str === null) return '';
  return str.split('\n').map((item, key) => {
    return (<span key={key}>{item}<br/></span>);
  });
};
