import { unescape } from 'html-escaper';
import {
  CustomerPoModel, CustomerReceiptModel, CustomerModel, UserModel,
  ProductModel, ProductUnitModel,
} from '.';

/*
  status : Number
    0 = Cancelled
    1 = Completed
*/

export class CustomerOrderModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.code = data.code? unescape(data.code): null;
    
    this.customerPo = new CustomerPoModel(data.customerPo? data.customerPo: {});
    this.customerReceipts = data.customerReceipts && data.customerReceipts.length 
      ? data.customerReceipts.map(d => new CustomerReceiptModel(d)): [];
    
    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.products = (data?.products || []).map(d => {
      return {
        product: new ProductModel(d.product? d.product: {}),
        productUnit: new ProductUnitModel(d.productUnit? d.productUnit: {}),
        quantity: Number(d.quantity? d.quantity: 0),
        price: Number(d.price? d.price: 0),
        note: d.note? unescape(d.note): '',
        stockOut: (d?.stockOut || []).map(k => {
          return {
            stockCode: k.stockCode? unescape(k.stockCode): '',
            quantity: Number(k.quantity? k.quantity: 0),
          };
        }),
      };
    });

    this.orderAt = data.orderAt? data.orderAt: null;
    this.payment = Number(data.payment? data.payment: 0);

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">ดำเนินการแล้ว</span>);
      else return (<span className="ss-tag bg-danger">คืนสินค้าแล้ว</span>);
    }else{
      return (<span className="ss-tag bg-danger">คืนสินค้าแล้ว</span>);
    }
  }
}
