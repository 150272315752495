import {
  PRODUCT_CATEGORIES,
  PRODUCTS,  PRODUCT_UNITS, 
} from '../actions/types';

const initialState = {
  productCategories: [],
  products: [],
  productUnits: [],
};

const productReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload };
    case PRODUCTS:
      return { ...state, products: action.payload };
    case PRODUCT_UNITS:
      return { ...state, productUnits: action.payload };

    default:
      return state;
  }
};

export default productReducer;