import { unescape } from 'html-escaper';

/*
  type
    0 = บุคคลธรมมดา
    1 = ห้างหุ้นส่วนจำกัด
    2 = บริษัทจำกัด

  branchType
    0 = บุคคลธรรมดา
    1 = สาขาใหญ่
    2 = สาขาย่อย
*/

export class BillingAddressModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.billingName = data.billingName? unescape(data.billingName): '';
    this.taxId = data.taxId? data.taxId: '';
    
    this.type = data.type? Number(data.type): 0;
    this.branchType = data.branchType? Number(data.branchType): 0;
    this.branchId = data.branchId? unescape(data.branchId): null;

    this.addressLocal = data.addressLocal? data.addressLocal: '';
    this.address = data.address? unescape(data.address): '';
    this.subdistrict = unescape(data.subdistrict? data.subdistrict: '');
    this.district = unescape(data.district? data.district: '');
    this.province = unescape(data.province? data.province: '');
    this.country = unescape(data.country? data.country: '');
    this.zipcode = data.zipcode? data.zipcode: '';

    this.addressText = data.addressText? unescape(data.addressText): '';

    this.telephone = data.telephone? data.telephone: '';
    this.instruction = data.instruction? unescape(data.instruction): '';

    this.sameAsShipping = data.sameAsShipping? Number(data.sameAsShipping): 0;

    this.isPrimary = data.isPrimary? Number(data.isPrimary): 0;
    this.isSelected = data.isSelected? Number(data.isSelected): 0;
  }

  isValid() {
    return this.billingName && this.taxId;
  }

  prefixSubdistrict() {
    if(!this.province) return 'แขวง / ตำบล';
    else if(this.province.includes('กรุงเทพ')) return 'แขวง';
    else return 'ตำบล';
  }
  prefixDistrict() {
    if(!this.province) return 'เขต / อำเภอ';
    else if(this.province.includes('กรุงเทพ')) return 'เขต';
    else return 'อำเภอ';
  }

  displayPrimary() {
    if(this.isValid()){
      if(this.isPrimary === 1) return (<span className="ss-tag bg-success">ใช่</span>);
      else return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่ใช่</span>);
    }
  }
  displaySelected() {
    if(this.isValid()){
      if(this.isSelected === 1) return (<span className="ss-tag bg-success">ใช้งานอยู่</span>);
      else return (<span className="ss-tag bg-warning">ไม่ได้ใช้</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่ได้ใช้</span>);
    }
  }
  displayType() {
    if(this.isValid()){
      if(this.type === 0) return 'บุคคลธรรมดา';
      else if(this.type === 1) return 'ห้างหุ้นส่วนจำกัด';
      else if(this.type === 2) return 'บริษัทจำกัด';
      else return '';
    }else{
      return '';
    }
  }
  displayBranchType() {
    if(this.isValid()){
      if(this.branchType === 1) return 'สาขาใหญ่';
      else if(this.branchType === 2) return 'สาขาย่อย';
      else return '';
    }else{
      return '';
    }
  }
}
