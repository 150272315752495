import { SupplierModel, SupplierPoModel, ProductsModel } from '.';

/*
  status : Number
    0 = Pending
    1 = Completed
*/

export class StockIncomingModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.code = data.code? unescape(data.code): null;
    
    this.supplier = new SupplierModel(data.supplier? data.supplier: {});
    this.supplierPo = new SupplierPoModel(data.supplierPo? data.supplierPo: {});

    this.products = data.products && data.products.length 
      ? data.products.map(d => new ProductsModel(d)): [];

    this.orderAt = data.orderAt? data.orderAt: null;

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">ดำเนินการแล้ว</span>);
      else return (<span className="ss-tag bg-warning">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag bg-warning">รอดำเนินการ</span>);
    }
  }
}
