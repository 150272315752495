import { SupplierPrModel, SupplierModel, UserModel } from '.';
import { unescape } from 'html-escaper';

/*
  status : Number
    0 = Pending
    1 = Rejected
    2 = Approved
    3 = Partially Paid
    4 = Completed
*/

export class SupplierPoModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.code = data.code? unescape(data.code): null;

    this.supplierPr = new SupplierPrModel(data.supplierPr? data.supplierPr: {});

    this.supplier = new SupplierModel(data.supplier? data.supplier: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.orderAt = data.orderAt? data.orderAt: null;
    this.paymentLeft = Number(data.paymentLeft? data.paymentLeft: 0);
    
    this.status = data.status? data.status: 0;

    this.createdAt = data.createdAt? data.createdAt: null;
    this.updatedAt = data.updatedAt? data.updatedAt: null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 4) return (<span className="ss-tag lg status-4">ดำเนินการแล้ว</span>);
      else if(this.status === 3) return (<span className="ss-tag lg status-3">แบ่งชำระ</span>);
      else if(this.status === 2) return (<span className="ss-tag lg status-2">อนุมัติแล้ว</span>);
      else if(this.status === 1) return (<span className="ss-tag lg status-1">ปฏิเสธ</span>);
      else return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }
  }
}
