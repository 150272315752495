import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onMounted, appLogo, appBg, appCopyright } from '../../helpers/frontend';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { userSignin } from '../../actions/user.actions';

function SigninPage(props) {
  const [values, setValues] = useState({ username: '', password: '' });
  const onChangeInput = (key, val, isNumber=false) => {
    if(isNumber) val = val || val===0? Number(val): '';
    setValues({ ...values, [key]: val });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    var res = await props.processSignin(values, true);
    if(res){
      setTimeout(() => {
        window.location.href = '/admin';
      }, 300);
    }else{
      onChangeInput('password', '');
    }
  };
  
  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
  /* eslint-enable */

  return !Object.keys(props.settings).length? (<></>): (
    <section className="auth-01 section-padding">
      <div className="bg-img" style={{ backgroundImage: `url('${appBg(props.settings['SYSTEM_BG'])}')` }}></div>
      <div className="container">
        <div className="auth-container bradius box-shadow">
          <div className="auth-body bg-white">
            <div className="title">
              <img className="logo" src={appLogo(props.settings['SYSTEM_LOGO'])} alt="Logo" />
              <div className="text">
                <h5 className="fw-900 lh-sm">เข้าสู่ระบบ</h5>
                <p className="fw-600 op-70">Warth Tree House</p>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="grids mt-4">
                <div className="grid sm-100">
                  <div className="form-control">
                    <input
                      type="text" required={true} className="lg" 
                      placeholder="ชื่อผู้ใช้ / อีเมล" 
                      value={values.username? values.username: ''} 
                      onChange={e => onChangeInput('username', e.target.value)} 
                    />
                  </div>
                </div>
                <div className="grid sm-100">
                  <div className="form-control">
                    <input
                      type="password" required={true} className="lg" 
                      placeholder="รหัสผ่าน "
                      value={values.password? values.password: ''} 
                      onChange={e => onChangeInput('password', e.target.value)} 
                    />
                  </div>
                </div>
              </div>
              <div className="btns">
                <button type="submit" className="btn btn-action btn-p btn-lg w-full">
                  เข้าสู่ระบบ
                </button>
              </div>
            </form>
            <p className="sm color-gray text-center mt-6">
              ลืมหรัสผ่าน?
              {' '}<Link to="/auth/forget-password" className="color-s h-color-p fw-600">
                ตั้งรหัสผ่านใหม่
              </Link>
            </p>
          </div>
          <div className="auth-footer bg-s color-white">
            <p className="xxs fw-300 text-center">
              {appCopyright(props.settings['SYSTEM_COMPANY_NAME'])}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

SigninPage.defaultProps = {
	
};
SigninPage.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
	processSignin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.app.settings,
});

export default connect(mapStateToProps, {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processSignin: userSignin,
})(SigninPage);