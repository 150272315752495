// App
export const CLIENT_IP = 'client/ip';
export const SIDENAV_ACTIVE_INDEX = 'sidenav/active-index';

export const APP_SETTINGS = 'app/settings';

export const APP_CURRENCY = 'app/currency';
export const APP_CURRENCIES = 'app/currencies';

export const APP_USERS = 'app/users';

// Alert
export const ALERT_LOADING = 'alert/loading';

// User
export const USER_SIGNIN = 'user/signin';
export const USER_SIGNOUT = 'user/signout';
export const USER_UPDATE = 'user/update';
export const USER_PERMISSIONS = 'user/permissions';
export const USER_FILES = 'user/files';

// Product
export const PRODUCT_CATEGORIES = 'product/categories';
export const PRODUCTS = 'product/list';
export const PRODUCT_UNITS = 'product/units';

// Supplier
export const SUPPLIERS = 'supplier/list';
export const SUPPLIER_PR = 'supplier/pr';
export const SUPPLIER_PO = 'supplier/po';
export const SUPPLIER_INVOICE = 'supplier/invoice';
export const SUPPLIER_RECEIPT = 'supplier/receipt';

// Stock
export const STOCK_INCOMINGS = 'stock-incoming/list';
export const STOCKS = 'stock/list';

// Customer
export const CUSTOMERS = 'customer/list';
export const CUSTOMER_SHIPPING_ADDRESSES = 'customer/shipping-addresses';
export const CUSTOMER_BILLING_ADDRESSES = 'customer/billing-addresses';

export const CUSTOMER_PR = 'customer/pr';
export const CUSTOMER_PO = 'customer/po';
export const CUSTOMER_INVOICE = 'customer/invoice';
export const CUSTOMER_RECEIPT = 'customer/receipt';
export const CUSTOMER_ORDERS = 'customer/orders';

// Finance
export const FINANCES = 'finance/list';
export const EMPLOYEE_TIMESHEETS = 'finance/timesheet';
