import {
  SUPPLIERS, 
  SUPPLIER_PR, SUPPLIER_PO, SUPPLIER_INVOICE, SUPPLIER_RECEIPT
} from '../actions/types';

const initialState = {
  suppliers: [],
  supplierProducts: [],
  supplierPr: [],
  supplierPo: [],
  supplierInvoice: [],
  supplierReceipt: [],
};

const supplierReducer = (state = initialState, action) => {
  switch(action.type) {

    case SUPPLIERS:
      return { ...state, suppliers: action.payload };
      
    case SUPPLIER_PR:
      return { ...state, supplierPr: action.payload };
    case SUPPLIER_PO:
      return { ...state, supplierPo: action.payload };
    case SUPPLIER_INVOICE:
      return { ...state, supplierInvoice: action.payload };
    case SUPPLIER_RECEIPT:
      return { ...state, supplierReceipt: action.payload };

    default:
      return state;
  }
};

export default supplierReducer;