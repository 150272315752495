import {
  FINANCES,
  EMPLOYEE_TIMESHEETS
} from '../actions/types';

const initialState = {
  finances: [],
  employeeTimesheets: [],
};

const financeReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case FINANCES:
      return { ...state, finances: action.payload };
    case EMPLOYEE_TIMESHEETS:
      return { ...state, employeeTimesheets: action.payload };

    default:
      return state;
  }
};

export default financeReducer;