import { ProductModel } from '.';
import { unescape } from 'html-escaper';

/*
  status
    0 = Inactive
    1 = Active
*/

export class ProductUnitModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    this.product = new ProductModel(data.product? data.product: {});
    
    this.sku = data.sku? data.sku: null;

    this.unit = data.unit? unescape(data.unit): null;
    this.convertedQuantity = data.convertedQuantity || data.convertedQuantity===0
      ? Number(data.convertedQuantity): null;

    this.supplierPrice = data.supplierPrice || data.supplierPrice===0 
      ? Number(data.supplierPrice): null;
    this.price = data.price? Number(data.price): null;

    this.weight = data.weight? Number(data.weight): null;
    this.width = data.width? Number(data.width): null;
    this.length = data.length? Number(data.length): null;
    this.height = data.height? Number(data.height): null;
    
    this.status = data.status? data.status: 0;

    //STOCK
    this.quantity = data.quantity? Math.floor(Number(data.quantity)): 0;
  }
  isValid() {
    return this._id? true: false;
  }

  displayDimension() {
    if(this.isValid()){
      return (<>{this.width} x {this.length} x {this.height} CM<sup>3</sup></>);
    }else{
      return (<></>);
    }
  }
  displayConvertedQuantity() {
    if(this.isValid() && this.product.isValid()){
      return `${this.convertedQuantity} ${this.product.unit}`;
    }else{
      return '-';
    }
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
}
